<template>
    <div>

        {{getServicePrice()}}
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'serviceprice',
    	props: {
    		pricelist: String
    	},
    	data: function() {
    		return {
    			priceArray: {},

    		};
    	},

    	methods: {
    		getServicePrice: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			var i;
    			var priceVal = 0;
    			for (i = 0; i < pricelist.length; i++) {

    				var price = pricelist[i];
    				if (price.service._id == 38079 || price.service._id == 38404 || price.service._id == 53395 || price.service._id == 38409) {
    					priceVal += price.price;
    				}
    			}

    			return "CHF " + parseFloat(Number((priceVal) / 100)).toFixed(2);
    		}

    	}
    };
</script>